<template>
  <section>
    <div class="container">
      <div class="row justify-center">
        <div class="col-md-10 col-lg-7 col-12">
          <div class="text-center">
            <span class="v-chip theme--light v-size--small primary white--text"
              ><span class="v-chip__content"> Feature 2 </span></span
            >
            <h2 class="section-title font-weight-medium">
              Awesome with Extra Ordinary Flexibility
            </h2>
            <p>
              You can relay on our amazing features list and also our customer
              services will be great experience for you without doubt and in
              no-time
            </p>
          </div>
        </div>
      </div>
      <div class="row feature2-spacer justify-center">
        <div class="mb-15 col-lg-6 col-12">
          <div class="v-card v-sheet theme--light elevation-0">
            <div class="row">
              <div class="py-0 col-md-8 col-12">
                <div class="v-card__text">
                  <div class="d-flex align-center">
                    <div class="row">
                      <div class="col-md-4 col-12">
                        <img
                          src="/testlocation.jpg"
                          alt="feature2"
                          class="img-fluid rounded"
                        />
                      </div>
                      <div class="px-md-0 d-flex align-center col-md-8 col-12">
                        <h4 class="font-weight-medium font-18">
                          Make your website in no-time with us.
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="py-0 col-md-4 col-12">
                <button
                  type="button"
                  class="
                    linking
                    feature2-action-btn
                    v-btn v-btn--block v-btn--has-bg
                    theme--light
                    v-size--default
                    info
                  "
                >
                  <span class="v-btn__content">
                    Let's Talk <i class="mdi mdi-arrow-right"></i
                  ></span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="mb-15 col-lg-6 col-12">
          <div class="v-card v-sheet theme--light elevation-0">
            <div class="row">
              <div class="py-0 col-md-8 col-12">
                <div class="v-card__text">
                  <div class="d-flex align-center">
                    <div class="row">
                      <div class="col-md-4 col-12">
                        <img
                          src="/_nuxt/img/img2.e55fee0.jpg"
                          alt="feature2"
                          class="img-fluid rounded"
                        />
                      </div>
                      <div class="px-md-0 d-flex align-center col-md-8 col-12">
                        <h4 class="font-weight-medium font-18">
                          Make your website in no-time with us.
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="py-0 col-md-4 col-12">
                <button
                  type="button"
                  class="
                    linking
                    feature2-action-btn
                    v-btn v-btn--block v-btn--has-bg
                    theme--light
                    v-size--default
                    info
                  "
                >
                  <span class="v-btn__content">
                    Let's Talk <i class="mdi mdi-arrow-right"></i
                  ></span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="mb-15 col-lg-6 col-12">
          <div class="v-card v-sheet theme--light elevation-0">
            <div class="row">
              <div class="py-0 col-md-8 col-12">
                <div class="v-card__text">
                  <div class="d-flex align-center">
                    <div class="row">
                      <div class="col-md-4 col-12">
                        <img
                          src="/_nuxt/img/img3.3e794f9.jpg"
                          alt="feature2"
                          class="img-fluid rounded"
                        />
                      </div>
                      <div class="px-md-0 d-flex align-center col-md-8 col-12">
                        <h4 class="font-weight-medium font-18">
                          Make your website in no-time with us.
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="py-0 col-md-4 col-12">
                <button
                  type="button"
                  class="
                    linking
                    feature2-action-btn
                    v-btn v-btn--block v-btn--has-bg
                    theme--light
                    v-size--default
                    info
                  "
                >
                  <span class="v-btn__content">
                    Let's Talk <i class="mdi mdi-arrow-right"></i
                  ></span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="mb-15 col-lg-6 col-12">
          <div class="v-card v-sheet theme--light elevation-0">
            <div class="row">
              <div class="py-0 col-md-8 col-12">
                <div class="v-card__text">
                  <div class="d-flex align-center">
                    <div class="row">
                      <div class="col-md-4 col-12">
                        <img
                          src="/_nuxt/img/img4.fc7922a.jpg"
                          alt="feature2"
                          class="img-fluid rounded"
                        />
                      </div>
                      <div class="px-md-0 d-flex align-center col-md-8 col-12">
                        <h4 class="font-weight-medium font-18">
                          Make your website in no-time with us.
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="py-0 col-md-4 col-12">
                <button
                  type="button"
                  class="
                    linking
                    feature2-action-btn
                    v-btn v-btn--block v-btn--has-bg
                    theme--light
                    v-size--default
                    info
                  "
                >
                  <span class="v-btn__content">
                    Let's Talk <i class="mdi mdi-arrow-right"></i
                  ></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>